import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	Checkbox,
	ColorPicker,
	Divider,
	Dropdown,
	Form,
	Input,
	Popconfirm,
	Select,
	Space,
	Table,
	Tabs,
	Tag,
	Popover,
	Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import Emitter from "../utils/emitter";
import { ColumnsType } from "antd/es/table";
import TextArea from "antd/es/input/TextArea";
import { GetAntIcon } from "../utils/ant_icons";
import {
	getAddButtonProp,
	getCrudaClass,
	isAddButtonVisible,
	isDeleteButtonVisible,
	multiCheckItems,
} from "../utils/lookup_list";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "./FormButtonSave";
import { HomeContext } from "../containers/Home";
import { getTenantSpecificUsers } from "../services/api-server/graphql";
import CustomForm from "./CustomForm";
import { AnyMxRecord, lookup } from "dns";
import { useNavigate } from "react-router";
import { getWellStatusTypeList } from "../services/api-server/status_types";
import { getWellStatusList } from "../services/api-server/status";
import { getWellPurposeTypeList } from "../services/api-server/purpose_types";
import { getWellPurposeList } from "../services/api-server/purposes";
import { getProjectTagList } from "../services/api-server/project_tags";
import { updateDeliverableRoles } from "../services/api-server/deliverables";
import {
	getRolePermission,
	getRolePermissionList,
} from "../services/api-server/roles_permissions";
import { refreshTenantRoutes } from "../utils/utils";
import PhaseReorder from "./Modal/PhaseReorder";
import AuditLogModal from "./Modal/AuditLogModal";

const CRUDTabTableModal = (props: any) => {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();

	const [tabKey, setTabKey] = useState<any>(() => {
		return sessionStorage.getItem("tabkey") || props.items[0].key;
	});
	const [tabData, setTabData] = useState<any>(props.items[0]);
	const [image, setImage] = useState<any>("");
	const [formRef] = useForm();
	const formValues = Form.useWatch([], formRef);

	const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
	const [filteredTableColumns, setFilteredTableColumns] = useState<any>(null);
	const [dataTable, setDataTable] = useState<any[]>([]);
	const [extraData, setExtraData] = useState<any>(null);
	const [filteredExtraData, setFilteredExtraData] = useState<any>(null);

	const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
	const [sideContainerOpen, setSideContainerOpen] = useState<any>(false);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [activeRowID, setActiveRowID] = useState<any>(null);
	const [activeRecord, setActiveRecord] = useState<any>(null);
	const [disabledTooltipMessage, setDisabledTooltipMessage] =
		useState<any>(null);
	const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
	const [newForm, setNewForm] = useState<any>(false);

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);

	const [formOption, setFormOption] = useState<any>(props?.formOption);
	const [addButtonProp, setAddButtonProp] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);
	const [tempRecord, setTempRecord] = useState<any>(null);

	const [tableHeight, setTableHeight] = useState<any>(null);
	const [ready, setReady] = useState<any>(false);
	const [initialForm, setInitialForm]: any = useState<any>(null);

	const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

	const [modalVisible, setModalVisible] = useState(false);
	const [savedValues, setSavedValues] = useState<any>(null);
	const [popConfirmMessage, setPopConfirmMessage] = useState<any>(null);
	const [auditLogModalOpen, setAuditLogModalOpen] = useState<boolean>(false);
	const [auditLogProps, setAuditLogProps] = useState<any>(null);

	const calculateTableHeight = () => {
		const table: any = document.getElementById("table-container");
		if (table) {
			setTableHeight(table?.clientHeight - 65 - 64);
		}
	};

	// Function to handle opening the modal for either add or update
	const openModal = (record?: any) => {
		if (record) {
			resetForm(record);
		} else {
			resetForm();
		}
		setSideContainerOpen(true);
	};

	// Function to handle closing the modal
	const closeModal = () => {
		setSideContainerOpen(false);
	};

	//Setting search record
	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	useEffect(() => {
		if (activeRowKey) {
			setPopConfirmMessage(null);
		} else {
			setPopConfirmMessage(props.popconfirmTitle);
		}
	}, [activeRowKey]);

	const fetchSaveMessage = () => {
		switch (tabKey) {
			case "strategic_business_unit":
				return "Updates on 'Strategic business unit ID' will not be reflected on the existing project codes that uses this SBU";
			case "company":
				return "Updates on 'Company name' will not be reflected on the existing project codes that uses this company";
			case "project_tags":
			case "well_purpose":
			case "well_status":
				return "This will update tag in all groups. Do you wish to proceed?";
			default:
				return "This will update tag in all groups. Do you wish to proceed?";
		}
	};

	//Filter data and table columns based on search record
	useEffect(() => {
		let data: any = [...dataTable];
		if (searchRecord?.length > 0) {
			if (tabKey == "company") {
				data = [...extraData.company];
			} else if (tabKey === "phase") {
				data = data.map((element: any) => {
					const task_list = extraData.task_data?.[element._id].map(
						(task: any) => task.task_name
					);
					element.task_list = task_list;
					return element;
				});
			} else {
				data = [...dataTable];
			}

			let filteredData = data
				?.map((element: any) => {
					let bool = searchRecord.every((item: any) => {
						if (Array.isArray(element[item.columnKey])) {
							return element[item.columnKey].some((arrayItem: any) =>
								arrayItem.toLowerCase().includes(item.value.toLowerCase())
							);
						} else {
							return element[item.columnKey]
								?.toLowerCase()
								.includes(item.value.toLowerCase());
						}
					});
					if (bool) return element;
				})
				.filter((element: any) => element !== null && element !== undefined);
			setFilteredDataTable(filteredData);
			data = filteredData;
		} else {
			setFilteredDataTable(null);
		}
		if (tableColumns?.length > 0) {
			resetColumns(data);
		}
	}, [searchRecord]);

	//Re-render table columns for OnCell/rowspan stuff
	const resetColumns = (data: any) => {
		let rowGroup: any = {};
		data.forEach((element: any) => {
			if (!rowGroup[element.group]) {
				rowGroup[element.group] = {
					count: 1,
					id: element.tag_uuid || element.type_uuid || element._id,
				};
			} else {
				rowGroup[element.group].count += 1;
			}
		});

		const filteredTableColumns = [...tableColumns].map((element: any) => {
			if (element.isGroup == true) {
				let key = "uuid";
				if (tabKey === "project_tags") {
					key = "tag_uuid";
				} else if (tabKey === "well_purpose" || tabKey === "well_status") {
					key = "type_uuid";
				} else if (tabKey === "well_operation") {
					key = "_id";
				}

				element.onCell = (record: any, rowIndex: any) => ({
					className: "antd-table-parent-group-cell",
					rowSpan:
						record.group == ""
							? 1
							: rowGroup[record.group]?.id == record[key]
							? rowGroup[record.group]?.count
							: 0,
				});
			}
			return element;
		});
		setFilteredTableColumns(filteredTableColumns);
	};

	//TabKey handler
	useEffect(() => {
		if (
			props.items.find(
				(element: any) => element.key === sessionStorage.getItem("tabkey")
			)
		) {
			setTabKey(sessionStorage.getItem("tabkey"));
			setTabData(props.items.find((element: any) => element.key === tabKey));
			setExpandRowKey([]);
			setSideContainerOpen(false);
			setActiveRowKey(null);
			setActiveRowID(null);
			setActiveRecord(null);
			setActiveGroupKey(null);
			resetForm();
			setNewForm(false);
		} else {
			setTabKey(props.items[0].key);
			setTabData(props.items[0]);
			setExpandRowKey([]);
			setSideContainerOpen(false);
			setActiveRowKey(null);
			setActiveRowID(null);
			setActiveRecord(null);
			setActiveGroupKey(null);
			resetForm();
			setNewForm(false);
		}
	}, [props.tabkey]);

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTableColumns([]);
			setFilteredTableColumns(null);
			setFilteredDataTable(null);
			setDataTable([]);
			setFormOption(null);
			let foundTabData: any = props?.items?.find((tab: any) => tab.key === key);
			setTabData(foundTabData);
			setTabKey(key);
			sessionStorage.setItem("tabkey", key);
			setExpandRowKey([]);
			setSideContainerOpen(false);
			setFilteredExtraData(null);
			setExtraData(null);
			setActiveRowKey(null);
			setActiveRowID(null);
			setActiveRecord(null);
			setActiveGroupKey(null);
			resetForm();
			setNewForm(false);
		} catch {}
	};

	useEffect(() => {
		Emitter.on("resetForm", (payload: any) => {
			// reset form if there is an active record (the side container is open)
			if (activeRecord) {
				resetForm(payload);
			}
		});

		return () => {
			Emitter.remove("resetForm");
		};
	});

	const handleKeyClick = async (
		record: any,
		element: any,
		key: any,
		extradata: any
	) => {
		try {
			await context.handlePageChange();
			setSideContainerOpen(true);
			if (tabKey === "well_purpose" || tabKey === "well_status") {
				if (element.dataIndex === "group") {
					setActiveRowKey(key);
					setActiveGroupKey(null);
					setFormOption(element.key);
				} else {
					setActiveRowKey(record?.type_uuid);
					setActiveGroupKey(`${record.group_uuid}-${record.type_uuid}`);
					setFormOption(element.key);
				}
			}
			if (tabKey === "well_operation") {
				if (element.dataIndex === "group" || element.dataIndex === "well") {
					console.log("Group or well clicked", element, formOption);
					setFormOption("existing_well");
				}
				setActiveGroupKey(null);
				setActiveRowKey(record.well_id || record._id);
			} else {
				if (element.dataIndex === "group") {
					setFormOption(element.key);
				}
				setActiveGroupKey(null);
				setActiveRowKey(key);
			}

			setActiveRowID(record?._id);
			setActiveRecord(record);

			resetForm(record, true, extradata);
		} catch {}
	};

	const handleTagClick = async (record: any, element: any, key: any) => {
		if (element.key != activeRowKey) {
			try {
				await context.handlePageChange(key);
				setSideContainerOpen(true);
				console.log(record, element, key);
				if (tabKey === "project_tags") {
					setActiveRowKey(record?.tag_uuid);
					setActiveGroupKey(`${record.group_uuid}-${record.tag_uuid}`);
				} else if (tabKey === "well_purpose" || tabKey === "well_status") {
					setActiveRowKey(record?.type_uuid);
					setActiveGroupKey(`${record.group_uuid}-${record.type_uuid}`);
				} else {
					setActiveRowKey(key);
				}
				setFormOption(element.key);
				setActiveRecord(record);
				setActiveRowID(record?._id);

				resetForm(record);
			} catch {}
		}
	};

	const checkIfTagExistInOtherGroup = (activeRowKey: any): boolean => {
		try {
			const getTagsorTypes = fetchAllTags();
			let matchingTagCount = 0;
			for (let i = 0; i < getTagsorTypes.length; i++) {
				const tag_or_types = getTagsorTypes[i];
				if (tag_or_types !== null && tag_or_types === activeRowKey) {
					matchingTagCount++;
				}
			}

			if (matchingTagCount > 1) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error("Error checking tag existence:", error);
			throw error;
		}
	};

	const customTenantUpdateHandler = (currentExtraData: any) => {
		Emitter.emit("loading", true);
		setImage(activeRecord?.logo || null);
		getTenantSpecificUsers(activeRowKey)
			.then((tenantUsers: any) => {
				let tempExtraData: any = { ...currentExtraData, users: tenantUsers };
				setExtraData(tempExtraData);
				let userValue: any = [];
				if (formRef.getFieldValue("project_managers"))
					userValue = [...formRef.getFieldValue("project_managers")];
				let users = tempExtraData?.users?.filter(
					(element: any) => !userValue.includes(element.mail)
				);

				let companyValue: any = [];
				if (formRef.getFieldValue("company_list"))
					companyValue = [...formRef.getFieldValue("company_list")];
				let company = tempExtraData?.company?.filter(
					(element: any) => !companyValue.includes(element.uuid)
				);
				setFilteredExtraData({ company, users });
			})
			.catch((error: any) => {})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const fetchAllTags = () => {
		let data = [...dataTable];
		let allTagsOrTypes = data?.map((element: any) => {
			return element.tag_uuid || element.type_uuid;
		});
		return allTagsOrTypes;
	};

	const checkIfCompanyHasSubsidiaries = (activeRowKey: any) => {
		// getLookupItem(tabKey, context?.currentTenantKey, {
		// 	project_code: props.currentProject,
		// });
		let data: any = [];

		if (
			tabKey === "company" &&
			extraData.company &&
			Array.isArray(extraData.company)
		) {
			data = [...extraData.company];

			for (const element of data) {
				if (
					element.uuid === activeRowKey &&
					element.subsidiaries &&
					Array.isArray(element.subsidiaries) &&
					element.subsidiaries.length > 0
				) {
					return true; // Return true if activeRowKey is found and has subsidiaries
				}
			}
		}

		return false; // Return false if activeRowKey is not found or doesn't have subsidiaries
	};

	const checkIfGroupOrTag = (activeRowKey: any) => {
		// getLookupItem(tabKey, context?.currentTenantKey);
		let data = [...dataTable];

		if (
			tabKey === "project_tags" ||
			tabKey === "well_purpose" ||
			tabKey === "well_status"
		) {
			for (const element of data) {
				if (element.group_uuid === activeRowKey) {
					return true;
				}
			}
		}

		return false;
	};

	const createHierarchy = (
		data: any,
		parentItem: any = null,
		fullData: any = null
	) => {
		const children = data
			.filter((item: any) => parentItem.subsidiaries.includes(item.uuid))
			?.map((item: any) => ({
				...item,
				key: item.uuid,
				parent: parentItem.name,
				children: createHierarchy(data, item, fullData),
			}));

		return children.length > 0 ? children : null;
	};

	//Very special case
	useEffect(() => {
		if (tabKey === "tenant" && activeRowKey) {
			customTenantUpdateHandler(extraData);
		}
	}, [activeRowKey, activeRecord, updated]);

	useEffect(() => {
		setReady(false);
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});
		Emitter.emit("loading", true);
		setAddButtonProp(getAddButtonProp(tabKey));

		getCrudaClass(tabKey)
			.then((crudaClassInstance: any) => {
				crudaClassInstance
					?.getLookupItem(
						tabKey,
						context?.currentTenantKey,
						props.otherData,
						formOption
					)
					.then((_data: any) => {
						let data: any = [];
						let lookupData: any = null;
						let rowGroup: any = {};
						if (tabKey == "project_tags") {
							const tagList = _data.tag || [];
							const tagGroupList = _data.tagGroup || [];
							const renderedTags: any = [];
							let finalData: any = [];

							tagGroupList.forEach((element: any) => {
								let tags = element.tags || [];
								if (tags.length > 0) {
									tags.forEach((tagId: any) => {
										let foundTag = tagList.find(
											(tag: any) => tag.uuid === tagId
										);
										if (foundTag) {
											if (renderedTags.indexOf(tagId) === -1) {
												renderedTags.push(tagId);
											}
											finalData.push({
												_id: foundTag._id,
												tags: element.tags,
												group: element.name,
												name: foundTag.name,
												color: foundTag.color,
												description: foundTag.description,
												group_uuid: element.uuid,
												tag_uuid: foundTag.uuid,
											});
										}
									});
								} else {
									finalData.push({
										group: element.name,
										tags: element.tags,
										name: null,
										color: null,
										description: null,
										group_uuid: element.uuid,
										tag_uuid: null,
									});
								}
							});
							tagList.forEach((element: any) => {
								if (renderedTags.indexOf(element.uuid) === -1) {
									finalData.push({
										_id: element._id,
										group: "",
										tags: [],
										name: element.name,
										color: element.color,
										description: element.description,
										group_uuid: "",
										tag_uuid: element.uuid,
									});
								}
							});
							data = finalData;

							// data = data.sort((a: any, b: any) => a.group.localeCompare(b.group));
							data = data.sort(
								(a: any, b: any) =>
									a.group.localeCompare(b.group) ||
									(a.name !== null && b.name !== null
										? a.name.localeCompare(b.name)
										: 0)
							);

							data.forEach((element: any) => {
								if (!rowGroup[element.group]) {
									rowGroup[element.group] = {
										count: 1,
										id: element.tag_uuid,
									};
								} else {
									rowGroup[element.group].count += 1;
								}
							});
							setExtraData({
								tag: _data.tag,
								tagGroup: _data.tagGroup,
							});
						} else if (tabKey == "well_purpose" || tabKey == "well_status") {
							const typeList = _data.type || [];
							const wellPurposeOrStatusList =
								_data.purpose || _data.status || [];
							const renderedTypes: any = [];
							let finalData: any = [];

							wellPurposeOrStatusList.forEach((element: any) => {
								let types = element.types || [];
								if (types.length > 0) {
									types.forEach((typeId: any) => {
										let foundType = typeList.find(
											(type: any) => type.uuid === typeId
										);
										if (foundType) {
											if (renderedTypes.indexOf(typeId) === -1) {
												renderedTypes.push(typeId);
											}
											finalData.push({
												types: element.types,
												group: element.name,
												name: foundType.name,
												description: foundType.description,
												group_uuid: element.uuid,
												type_uuid: foundType.uuid,
											});
										}
									});
								} else {
									finalData.push({
										group: element.name,
										types: element.types,
										name: null,
										description: null,
										group_uuid: element.uuid,
										type_uuid: null,
									});
								}
							});
							typeList.forEach((element: any) => {
								if (renderedTypes.indexOf(element.uuid) === -1) {
									finalData.push({
										group: "",
										types: [],
										name: element.name,
										description: element.description,
										group_uuid: "",
										type_uuid: element.uuid,
									});
								}
							});
							data = finalData;

							data = data.sort(
								(a: any, b: any) =>
									a.group.localeCompare(b.group) ||
									(a.name !== null && b.name !== null
										? a.name.localeCompare(b.name)
										: 0)
							);
							data.forEach((element: any) => {
								if (!rowGroup[element.group]) {
									rowGroup[element.group] = {
										count: 1,
										id: element.type_uuid,
									};
								} else {
									rowGroup[element.group].count += 1;
								}
							});

							setExtraData({
								type: _data.type,
								purpose: _data.purpose,
								status: _data.status,
							});
						} else if (tabKey == "well_operation") {
							const finalData: any = [];
							const wellData = _data?.tableData || null;
							wellData.forEach((element: any) => {
								finalData.push({
									...element,
									group: element.well_name,
								});
							});
							data = finalData;
							data.forEach((element: any) => {
								if (!rowGroup[element.group]) {
									rowGroup[element.group] = {
										count: 1,
										id: element._id,
									};
								} else {
									rowGroup[element.group].count += 1;
								}
							});
							setExtraData({
								...context?.allData,
								well_data: _data?.well_data,
								operation_data: _data?.operation_data,
							});
						} else if (tabKey == "region") {
							setExtraData({ country: _data.country });
							data = _data.region;
						} else if (tabKey == "operating_office") {
							setExtraData({ country: _data.country });
							data = _data.office;
						} else if (tabKey == "company") {
							setExtraData({
								company: _data.company,
								country: _data.country,
								region: _data.region,
							});
							data = _data.company;

							const flatData = data?.map((item: any) => ({
								...item,
								key: item.uuid,
								region_name: _data.region?.find(
									(element: any) => item.region === element.code
								)?.name,
								country_name: _data.country?.find(
									(element: any) => item.country === element["alpha-3"]
								)?.name,
							}));
							// Create the hierarchy starting from the root level
							const rootObjects = flatData?.filter(
								(item: any) =>
									!data.some((obj: any) => obj.subsidiaries.includes(item.uuid))
							);

							const hierarchicalData = rootObjects?.map((item: any) => ({
								...item,
								key: item.uuid,
								children: createHierarchy(flatData, item, _data),
							}));

							const availableSubsidiaryOptions = findRootCompanies(flatData);

							data = hierarchicalData;
							setExtraData({
								company: flatData,
								country: _data?.country,
								region: _data?.region,
							});
							setFilteredExtraData({
								company: availableSubsidiaryOptions || [],
								country: _data?.country || [],
								region: _data?.region || [],
							});
						} else if (tabKey == "phase") {
							if (formOption == "template") {
								data = props.otherData?.phases;
								let templateTaskData: any = {};
								data?.forEach((element: any) => {
									templateTaskData[element._id] = element.tasks;
								});

								lookupData = { task_data: templateTaskData };
								// Assign task data to phase
								data = data.map((element: any) => {
									const task_data = lookupData?.task_data?.[element._id].map(
										(task: any) => task
									);
									element.task_data = task_data;
									return element;
								});
								setExtraData(lookupData);
							} else {
								data = _data?.phase_data;
								lookupData = { task_data: _data?.task_data };
								// Assign task data to phase
								// match lookup data with data and add into a new field called task_data in data
								data = data.map((element: any) => {
									const task_data = lookupData?.task_data?.[element._id].map(
										(task: any) => task
									);
									element.task_data = task_data;
									return element;
								});
								setDisabledTooltipMessage(
									<span>
										Further edits on this phase are not allowed as it is
										excluded
									</span>
								);
								setExtraData(lookupData);
							}
						} else if (tabKey == "well_project") {
							data = _data?.tableData || null;
							lookupData = _data?.lookupData || null;
							if (data) {
								for (let d of data) {
									d.operation_type_name =
										_data?.lookupData?.operation_type?.find(
											(item: any) => item.uuid === d.operation_type
										)?.name;
								}
							}

							if (lookupData) {
								if (tabKey === "tenant" && activeRowKey) {
									customTenantUpdateHandler(lookupData);
								} else {
									setExtraData(lookupData);
								}
							}
						} else {
							data = _data?.tableData || _data;
							lookupData = _data?.lookupData || null;
							if (lookupData) {
								if (tabKey === "tenant" && activeRowKey) {
									customTenantUpdateHandler(lookupData);
								} else {
									setExtraData(lookupData);
								}
							}
						}
						let columns: any = crudaClassInstance.getColumnList(
							tabKey,
							handleSearch,
							context?.allData,
							lookupData,
							formOption
						);

						if (data) {
							let key = columns[0].dataIndex;
							data = data
								?.map((element: any) => {
									fetchAllTags();
									if (tabKey === "operating_office") {
										element.office_name = `${element.name}, ${element.country}`;
									}
									return {
										...element,
										// key: element.uuid || element.group_uuid,
									};
								})
								.sort((a: any, b: any) => a[key].localeCompare(b[key]));

							if (tabData?.sortKey) {
								console.log(tabData);
								// Sorting data that handle number and other type of data
								try {
									data = data.sort((a: any, b: any) => {
										if (typeof a[tabData?.sortKey] === "number") {
											return a[tabData?.sortKey] - b[tabData?.sortKey];
										}
										if (typeof a[tabData?.sortKey] === "string") {
											return a[tabData?.sortKey].localeCompare(
												b[tabData?.sortKey]
											);
										}
										return 0;
									});
								} catch (error) {
									if (error instanceof TypeError) {
										console.error(
											"Caught a TypeError while sorting:",
											error.message
										);
									} else {
										console.error("Error sorting:", error);
									}
								}
							}

							let renderedColumns = [];
							if (columns) {
								renderedColumns = columns?.map((element: any) => {
									if (element.isActive == true && !element.render) {
										element.render = (text: any, record: any) => {
											const id = record.uuid || record.group_uuid || record._id;
											if (text) {
												if (element.tag) {
													return (
														<Tag
															color={record.color}
															key={record.name + record.group}
															onClick={() => {
																handleTagClick(record, element, id);
																setNewForm(false);
															}}
															className="table-clickable-text"
															style={{
																whiteSpace: "normal",
																wordWrap: "break-word",
															}}
														>
															{text}
														</Tag>
													);
												} else {
													return (
														<Tooltip
															title={
																record.disabled
																	? `${tabKey} is excluded. Click to view details.`
																	: ""
															}
														>
															<a
																onClick={() => {
																	handleKeyClick(
																		record,
																		element,
																		id,
																		lookupData
																	);
																	setNewForm(false);
																}}
																className="table-clickable-text"
															>
																{text}
															</a>
														</Tooltip>
													);
												}
											}
										};
									}
									if (element.isGroup == true) {
										let key = "uuid";
										if (tabKey === "project_tags") {
											key = "tag_uuid";
										} else if (
											tabKey === "well_purpose" ||
											tabKey === "well_status"
										) {
											key = "type_uuid";
										} else if (tabKey === "well_operation") {
											key = "_id";
										}

										element.onCell = (record: any, rowIndex: any) => ({
											rowSpan:
												record.group == ""
													? 1
													: rowGroup[record.group].id == record[key]
													? rowGroup[record.group].count
													: 0,
										});
									}
									return element;
								});
								setTableColumns(renderedColumns);
								setDataTable(data);
							} else {
								setTableColumns(columns);
								setDataTable([]);
							}
						} else {
							setTableColumns(columns);
							setDataTable([]);
						}
					})
					.catch((e: any) => {
						setTableColumns([]);
						setDataTable([]);
					})
					.finally(() => {
						setReady(true);
						calculateTableHeight();
						setFilteredDataTable(null);
						setSearchRecord([]);
						setTempRecord(null);
						Emitter.emit("loading", false);
					});
			})
			.catch((e: any) => {
				// console.log(e);
				setTableColumns([]);
				setDataTable([]);
				Emitter.emit("loading", false);
			});
	}, [tabKey, updated, context.currentTenantKey, props.otherData]);

	useEffect(() => {
		resetForm();
	}, []);

	//Emitter Handlers
	useEffect(() => {
		Emitter.on("forceUpdate", (payload: any = null) => {
			payload && setActiveRecord({ ...activeRecord, ...payload.data });
			setUpdated(!updated);
		});

		// Dangerous for updating template Task stuff
		Emitter.on("forceUpdateTask", (payload: any) => {
			setUpdated(!updated);
		});

		return () => {
			Emitter.remove("forceUpdate");
			Emitter.remove("forceUpdateTask");
		};
	}, [activeRowKey, dataTable, updated]);

	const findRootParent = (key: any, companies: any) => {
		let foundCompany = companies?.find((company: any) =>
			company?.subsidiaries?.includes(key)
		);
		if (foundCompany) {
			let tempRoot: any = findRootParent(foundCompany.uuid, companies);
			if (tempRoot) {
				return tempRoot;
			} else {
				return foundCompany.uuid;
			}
		} else {
			return key;
		}
	};

	useEffect(() => {
		if (extraData && activeRowKey) {
			let fieldValue: any = null;
			let filteredData: any = null;
			switch (tabKey) {
				case "region":
					fieldValue = [...formRef.getFieldValue("countries")];

					filteredData = extraData["country"].filter(
						(element: any) => !fieldValue.includes(element["alpha-3"])
					);
					setFilteredExtraData({ country: filteredData });
					break;
				case "well_purpose":
				case "well_status":
					if (formOption === "well_purpose" || formOption === "well_status") {
						// Remove object that has the same uuid as the uuid in types in the dataTable
						filteredData = extraData["type"]?.filter(
							(element: any) =>
								// !fieldValue?.includes(element["uuid"]) &&
								!dataTable.find((item: any) =>
									item.types.includes(element["uuid"])
								)
						);
						setFilteredExtraData({ ...extraData, type: filteredData });
					}
					break;
				case "company":
					let tempData = { ...extraData };
					fieldValue = formRef.getFieldValue("subsidiaries") || [];
					let parentValue = formRef.getFieldValue("parent") || "";
					// console.log(fieldValue);
					let rootKey = findRootParent(activeRowKey, extraData["company"]);
					// console.log(rootKey);
					// console.log(extraData["company"]);

					tempData.company = findRootCompanies(extraData["company"])?.filter(
						(element: any) =>
							activeRowKey != element["uuid"] &&
							rootKey != element["uuid"] &&
							element.name != parentValue
					);

					if (formRef.getFieldValue("region")) {
						let indexArray = tempData?.region?.find(
							(object: any) => object.code === formRef.getFieldValue("region")
						);
						if (indexArray) {
							let filteredData: any = [];
							filteredData = indexArray?.countries
								?.map((object: any) => {
									let countries = tempData.country.find(
										(element: any) => element["alpha-3"] == object
									);

									if (countries) {
										return countries;
									}
								})
								.filter((item: any) => item);

							tempData.country = filteredData;
						}
					}
					setFilteredExtraData({ ...tempData });
					break;
			}
		} else if (extraData) {
			let filteredData: any = null;
			// switch (tabKey) {
			// 	case "well_purpose":
			// 	case "well_status":
			// 		if (formOption === "well_purpose" || formOption === "well_status") {
			// 			// Remove object that has the same uuid as the uuid in types in the dataTable
			// 			filteredData = extraData["type"]?.filter(
			// 				(element: any) =>
			// 					// !fieldValue?.includes(element["uuid"]) &&
			// 					!dataTable.find((item: any) =>
			// 						item.types.includes(element["uuid"])
			// 					)
			// 			);
			// 			setFilteredExtraData({ type: filteredData });
			// 		}
			// 		break;
			// }
		}
	}, [activeRowKey, extraData]);

	useEffect(() => {
		if (formOption === "existing_well" && formValues.well_name) {
			const well_id = formValues.well_name;
			const foundData = extraData.well_data.find((element: any) => {
				return element._id === well_id;
			});
			if (foundData) {
				// setActiveRowKey(well_id);
				// setActiveGroupKey(null);
				// setActiveRowID(foundData._id);
				setActiveRecord(foundData);
				formRef.setFieldsValue(foundData);
				// add well_id to formRef
				// formRef.setFieldsValue({ well_id });
				console.log("Found data", foundData);
			}
		}
	}, [formValues]);

	const findRootCompanies = (data: any) => {
		const keyMap = new Map();

		if (data) {
			// Populate the map and initialize the parent count for each object
			data.forEach((obj: any) => {
				keyMap.set(obj.key, obj);
				obj.parentCount = 0;
			});

			// Update parent count based on the links
			data.forEach((obj: any) => {
				if (obj.subsidiaries) {
					obj.subsidiaries.forEach((subsidiaryKey: any) => {
						const linkedObj = keyMap.get(subsidiaryKey);
						if (linkedObj) {
							linkedObj.parentCount++;
						}
					});
				}
			});

			// Filter objects with no parents
			const rootParents = data.filter((obj: any) => obj.parentCount === 0);

			// Clean up the added property before returning
			rootParents.forEach((obj: any) => delete obj.parentCount);

			return rootParents;
		} else {
			return [];
		}
	};

	const handleAddButton = () => {
		if (tabKey === "company") {
			setFilteredExtraData({
				...extraData,
				company: findRootCompanies(extraData.company),
			});
		}
	};

	const resetForm = (
		values: any = null,
		reset: any = true,
		_extraData: any = null
	) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			let filteredExtraData: any = {};
			if (formRef.getFieldValue("well_purpose")) {
				let lookup = extraData || _extraData;
				let foundData = lookup?.well_purpose?.find(
					(element: any) =>
						element.uuid === formRef.getFieldValue("well_purpose")
				);
				if (foundData) {
					filteredExtraData.well_purpose_type = lookup?.well_purpose_type
						?.map((element: any) => {
							if (foundData.types.includes(element.uuid)) return element;
						})
						.filter(Boolean);
				}
			}
			if (formRef.getFieldValue("well_status")) {
				let lookup = extraData || _extraData;
				let foundData = lookup?.well_status?.find(
					(element: any) =>
						element.uuid === formRef.getFieldValue("well_status")
				);
				if (foundData) {
					filteredExtraData.well_status_type = lookup?.well_status_type
						?.map((element: any) => {
							if (foundData.types.includes(element.uuid)) return element;
						})
						.filter(Boolean);
				}
			}

			if (Object.keys(filteredExtraData).length > 0) {
				let lookup = extraData || _extraData;
				setFilteredExtraData({ ...lookup, ...filteredExtraData });
			}
		}
	};

	const handleFormCancel = () => {
		context?.handleFormUpdating(false, activeRowKey);
		setSideContainerOpen(false);
		setFilteredExtraData(null);
		setActiveRowKey(null);
		setActiveRowID(null);
		setActiveGroupKey(null);
		setActiveRecord(null);
		resetForm();
		setNewForm(false);
	};

	// Update Lookup data
	const updateAllData = (
		data: any,
		type: any,
		lookupKey: any,
		formOption: any = null
	) => {
		const specialCases = [
			"well_purpose",
			"well_purpose_type",
			"well_status",
			"well_status_type",
			"project_tags",
		];
		if (formOption) {
			lookupKey = formOption;
		}
		switch (lookupKey) {
			case "roles_permissions":
				lookupKey = "roles";
				break;
			case "tag":
				lookupKey = "project_tags";
				break;
		}
		if (lookupKey in context.allData && !specialCases.includes(lookupKey)) {
			let index = -1;
			switch (type) {
				case "new":
					if (context.allData[lookupKey].length > 0) {
						context.allData[lookupKey].push(data);
					} else {
						context.allData[lookupKey] = [data];
					}
					break;
				case "update":
					index = context.allData[lookupKey].findIndex(
						(item: any) => item._id === data._id
					);

					if (index != -1) {
						context.allData[lookupKey][index] = data;
					}
					break;
				case "delete":
					console.log(data);
					index = context.allData[lookupKey].findIndex(
						(item: any) => item._id === data._id
					);
					console.log(index);
					if (index != -1) {
						context.allData[lookupKey].splice(index, 1);
					}
					break;
			}
		} else if (specialCases.includes(lookupKey)) {
			switch (lookupKey) {
				case "project_tags":
					getProjectTagList().then((_data: any) => {
						if (_data) {
							context.allData[lookupKey] = _data;
						}
					});
					break;
				case "well_purpose":
				case "well_purpose_type":
					getWellPurposeList().then((_data: any) => {
						if (_data) {
							context.allData["well_purpose"] = _data;
						}
					});
					getWellPurposeTypeList().then((_data: any) => {
						if (_data) {
							context.allData["well_purpose_type"] = _data;
						}
					});
					break;
				case "well_status":
				case "well_status_type":
					getWellStatusList().then((_data: any) => {
						if (_data) {
							context.allData["well_status"] = _data;
						}
					});
					getWellStatusTypeList().then((_data: any) => {
						if (_data) {
							context.allData["well_status_type"] = _data;
						}
					});
					break;
			}
		}

		console.log("Updated");
	};

	const handleModalOk = (values: any) => {
		getCrudaClass(tabKey).then((crudaClassInstance: any) => {
			const form = crudaClassInstance.getFormList(tabKey, formOption);
			const formList: any = [];
			form.forEach((element: any) => {
				if (element.children && !("name" in element)) {
					element.children.forEach((child_element: any) => {
						if (child_element.name) formList.push(child_element.name);
					});
				} else {
					if (element.name) formList.push(element.name);
				}
			});

			crudaClassInstance
				.updateLookupItem(
					tabKey,
					formOption,
					activeRowKey,
					context?.currentTenantKey,
					savedValues,
					initialForm,
					props.otherData,
					props.user,
					formList
				)
				.then((updatedItem: any) => {
					let keyName =
						tabKey.charAt(0).toUpperCase() +
						tabKey.split("_").join(" ").slice(1);
					Emitter.emit("alert", {
						type: "success",
						message: `${keyName} has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});

					console.log("UI", updatedItem);
					setActiveRecord(updatedItem);
					setSideContainerOpen(true);
					resetForm(updatedItem);
					// setFilteredExtraData(null);
					updateAllData(updatedItem, "update", tabKey, formOption);
					setUpdated(!updated);
				})
				.catch((error: any) => {
					console.log(error);
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {});
		});

		setModalVisible(false);
		setNewForm(false);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
		setNewForm(false);
	};

	const handleFormSave = (
		values: any,
		redirectPage: any = null,
		level = null
	) => {
		console.log("Form values", values);

		let _key = level || tabKey;
		getCrudaClass(_key).then((crudaClassInstance: any) => {
			let keyName =
				_key.charAt(0).toUpperCase() + _key.split("_").join(" ").slice(1);
			if (values.color && typeof values.color == "object") {
				values.color = values.color.toHexString();
			}

			// For each values in the form, trim the leading and trailing spaces
			Object.keys(values).forEach((key) => {
				if (typeof values[key] == "string") {
					values[key] = values[key].trim();
				}
			});

			const form = crudaClassInstance.getFormList(_key, formOption);
			const formList: any = [];
			form.forEach((element: any) => {
				if (element.children && !("name" in element)) {
					element.children.forEach((child_element: any) => {
						if (child_element.name) formList.push(child_element.name);
					});
				} else {
					if (element.name) formList.push(element.name);
				}
			});

			if (activeRowKey) {
				if (
					(_key === "project_tags" ||
						_key === "well_purpose" ||
						_key === "well_status") &&
					checkIfTagExistInOtherGroup(activeRowKey) === true &&
					initialForm.group_uuid === values.group_uuid
				) {
					setModalVisible(true);
					setSavedValues(values);

					// When a Project Tag is moved to another group, no prompt is shown as it changes only for that one tag
				} else if (
					(_key === "company" && initialForm.name !== values.name) ||
					(_key === "strategic_business_unit" && initialForm.id !== values.id)
				) {
					setModalVisible(true);
					setSavedValues(values);
				} else if (
					(_key === "project_tags" ||
						_key === "well_purpose" ||
						_key === "well_status") &&
					initialForm.group_uuid != values.group_uuid
				) {
					crudaClassInstance
						.updateLookupItem(
							_key,
							formOption,
							activeRowKey,
							context?.currentTenantKey,
							values,
							initialForm,
							props.otherData,
							props.user,
							formList
						)
						.then((updatedItem: any) => {
							Emitter.emit("alert", {
								type: "success",
								message: `${keyName} item has been moved to another group.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
							context?.handleFormUpdating(false, activeRowKey);
							setSideContainerOpen(true);
							setActiveRecord(updatedItem);
							resetForm(values, true);
							setUpdated(!updated);
							updateAllData(updatedItem, "update", _key, formOption);
							setNewForm(false);
						})
						.catch((error: any) => {
							console.log(error);
							Emitter.emit("alert", {
								type: "error",
								message: "Failed to update. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						})
						.finally(() => {});
				} else {
					//Set undefined to empty string
					Object.keys(values).forEach((element: any) => {
						if (values[element] == undefined) {
							if (element == "subsidiaries") {
								values[element] = [];
							} else {
								values[element] = null;
							}
						}
					});
					console.log("Updating form", formOption, values);
					crudaClassInstance
						.updateLookupItem(
							_key,
							formOption,
							activeRowKey,
							context?.currentTenantKey,
							values,
							initialForm,
							props.otherData,
							props.user,
							formList
						)
						.then((updatedItem: any) => {
							Emitter.emit("alert", {
								type: "success",
								message: `${keyName} item has been successfully updated.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
							context?.handleFormUpdating(false, activeRowKey);
							setSideContainerOpen(true);
							setActiveRecord(updatedItem);
							resetForm(values, true);
							setUpdated(!updated);
							updateAllData(updatedItem, "update", _key, formOption);
							setNewForm(false);

							if (_key === "tenant") {
								Emitter.emit("tenantUpdated", null);
							}

							if (redirectPage) {
								let projectInfo = context?.allData?.projects?.find(
									(element: any) =>
										element.project_code === props.otherData.project_code
								);
								sessionStorage.setItem("main-tabkey", "well-programme");
								navigate(
									`/projects/${projectInfo.project_code}/${updatedItem.operation_code}/${redirectPage}`,
									{
										replace: false,
										state: { ...projectInfo, ...updatedItem },
									}
								);
								context.setCurrentOperation(updatedItem);
							}
						})
						.catch((error: any) => {
							console.log(error);
							if (
								error?.code === 11000 &&
								formOption === "group" &&
								(_key === "project_tags" ||
									_key === "well_purpose" ||
									_key === "well_status")
							) {
								Emitter.emit("alert", {
									type: "error",
									message:
										"This Project group name already exists, please ensure the Project group name is unique. Changes are not saved.",
									description: "",
									top: true,
									closeable: false,
									timeout: 6000,
								});
							} else if (error?.code === 11000) {
								Emitter.emit("alert", {
									type: "error",
									message: `This ${keyName} already exists, please ensure the ${keyName} ${
										Object.keys(error.keyPattern)[0]
									} is unique. Changes are not saved.`,
									description: "",
									top: true,
									closeable: false,
									timeout: 6000,
								});
							} else {
								Emitter.emit("alert", {
									type: "error",
									message: "Changes are not saved. Please try again.",
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
							}
						})
						.finally(() => {});
				}
			} else {
				if (formOption === "existing_well") {
					values.well_id = activeRecord._id;
				}
				console.log("Adding form", values);
				const tenantKey = context?.currentTenantKey
					? context?.currentTenantKey
					: null;
				crudaClassInstance
					.addLookupItem(
						_key,
						formOption,
						values,
						props.otherData,
						tenantKey,
						extraData,
						props.user
					)
					.then((addedItem: any) => {
						console.log("Checking added item", addedItem);

						Emitter.emit("alert", {
							type: "success",
							message: `${keyName} item has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});

						context?.handleFormUpdating(false, "new");
						closeModal(); // Close the config modal for adding new data
						openModal(addedItem); // Open the modal for the recently added data
						setActiveRowKey(
							addedItem?.uuid ||
								addedItem?.group_uuid ||
								addedItem?.tag_uuid ||
								addedItem?._id
						);
						setActiveRowID(addedItem?._id);
						if (values?.group_uuid && addedItem?.uuid)
							setActiveGroupKey(`${values?.group_uuid}-${addedItem?.uuid}`);
						setActiveRecord(addedItem);
						resetForm(values, true);
						setUpdated(!updated);
						updateAllData(addedItem, "new", _key, formOption);
						setNewForm(false);

						if (redirectPage) {
							let projectInfo = context?.allData?.projects?.find(
								(element: any) =>
									element.project_code === props.otherData.project_code
							);

							sessionStorage.setItem("main-tabkey", "well-programme");
							navigate(
								`/projects/${addedItem.project_code}/${addedItem.operation_code}/${redirectPage}`,
								{
									replace: false,
									state: { ...projectInfo, ...addedItem },
								}
							);
						}
					})
					.catch((error: any) => {
						// console.log(error);
						if (
							error?.code === 11000 &&
							formOption === "group" &&
							(_key === "project_tags" ||
								_key === "well_purpose" ||
								_key === "well_status")
						) {
							Emitter.emit("alert", {
								type: "error",
								message:
									"This Project group name already exists, please ensure the Project group name is unique. Changes are not saved.",
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This ${keyName} already exists, please ensure the ${keyName} ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					})
					.finally(() => {
						console.log("Data successfully added");
					});
			}
		});
	};

	const handleFormDelete = () => {
		getCrudaClass(tabKey).then((crudaClassInstance: any) => {
			context?.handleFormUpdating(false, activeRowKey);
			let keyName =
				tabKey.charAt(0).toUpperCase() + tabKey.split("_").join(" ").slice(1);

			if (tabKey === "roles_permissions") {
				// Update all deliverables with the role removed
				getRolePermission(activeRowKey)
					.then((data: any) => {
						updateDeliverableRoles(context?.currentTenantKey, data._id).catch(
							(error: any) => {
								console.log(error);
							}
						);
						crudaClassInstance
							.deleteLookupItem(
								tabKey,
								formOption,
								activeRowKey,
								context?.currentTenantKey,
								{
									project_code: context?.currentProject?.project_code,
									operation_code: context?.currentOperation?.operation_code,
								},
								props.otherData,
								props.user
							)
							.then(() => {
								Emitter.emit("alert", {
									type: "success",
									message: `${keyName} item has been successfully deleted.`,
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
								updateAllData(
									{ _id: activeRowID },
									"delete",
									tabKey,
									formOption
								);
							})
							.catch(() => {
								Emitter.emit("alert", {
									type: "error",
									message: "Changes are not saved. Please try again.",
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
							})
							.finally(() => {
								setNewForm(false);
								resetForm(null, true);
								setUpdated(!updated);
								setSideContainerOpen(false);
								setActiveRowKey(null);
								setActiveRowID(null);
								setActiveGroupKey(null);
								setActiveRecord(null);
								setFilteredExtraData(null);
							});
					})
					.catch((error: any) => {
						console.log(error);
					});
			} else {
				crudaClassInstance
					.deleteLookupItem(
						tabKey,
						formOption,
						activeRowKey,
						context?.currentTenantKey,
						{
							project_code: context?.currentProject?.project_code,
							operation_code: context?.currentOperation?.operation_code,
						},
						props.otherData,
						props.user
					)
					.then(() => {
						Emitter.emit("alert", {
							type: "success",
							message: `${keyName} item has been successfully deleted.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						updateAllData({ _id: activeRowID }, "delete", tabKey, formOption);
					})
					.catch(() => {
						Emitter.emit("alert", {
							type: "error",
							message: "Changes are not saved. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
					})
					.finally(() => {
						setNewForm(false);
						resetForm(null, true);
						setUpdated(!updated);
						setSideContainerOpen(false);
						setActiveRowKey(null);
						setActiveRowID(null);
						setActiveGroupKey(null);
						setActiveRecord(null);
						setFilteredExtraData(null);
					});
			}
		});
	};

	const getDeleteMessage = (activeRowKey: any) => {
		// Determine the message based on the lookupItemType
		// getLookupItem(tabKey, context?.currentTenantKey);
		if (tabKey === "strategic_business_unit") {
			return (
				<>
					Permanently delete this business unit?
					<br />
					This action cannot be undone.
				</>
			);
		} else if (tabKey === "project_tags") {
			const isGroupOrTag = checkIfGroupOrTag(activeRowKey);
			return (
				<>
					{isGroupOrTag ? (
						<>
							Permanently delete this tag group?
							<br />
							Doing this will make the tags no longer be associated with any
							group.
						</>
					) : (
						<>
							Permanently delete this tag?
							<br />
							Deleting it will remove the tag from all linked projects.
						</>
					)}
				</>
			);
		} else if (tabKey === "well_purpose" || tabKey === "well_status") {
			const isGroupOrTag = checkIfGroupOrTag(activeRowKey);
			if (tabKey === "well_purpose") {
				return (
					<>
						{isGroupOrTag ? (
							<>
								Permanently delete this well purpose?
								<br />
								Doing this will make the purpose types no longer be associated
								with any well purpose.
							</>
						) : (
							<>
								Permanently delete this well purpose type?
								<br />
								Deleting it will remove the purpose type from all linked
								projects.
							</>
						)}
					</>
				);
			} else if (tabKey === "well_status") {
				return (
					<>
						{isGroupOrTag ? (
							<>
								Permanently delete this well status?
								<br />
								Doing this will make the status types no longer be associated
								with any well status.
							</>
						) : (
							<>
								Permanently delete this well status type?
								<br />
								Deleting it will remove the status type from all linked
								projects.
							</>
						)}
					</>
				);
			}
		} else if (tabKey === "operating_office") {
			return (
				<>
					Permanently delete this operating office?
					<br />
					This action cannot be undone.
				</>
			);
		} else if (tabKey === "company") {
			const hasSubsidiaries = checkIfCompanyHasSubsidiaries(activeRowKey);
			return (
				<>
					{hasSubsidiaries ? (
						<>
							Permanently delete this company?
							<br />
							Doing this will make its subsidiaries a parent company.
						</>
					) : (
						<>
							Permanently delete this company?
							<br />
							This action cannot be undone.
						</>
					)}
				</>
			);
		} else if (tabKey === "roles_permissions") {
			return (
				<>
					Delete role? This will also remove the role from associated
					deliverables.
				</>
			);
		} else if (tabKey === "well_programme_template") {
			return (
				<>
					Permanently delete this well programme template? This action cannot be
					undone.
				</>
			);
		} else {
			return "Permanently delete this item? This action cannot be undone.";
		}
	};

	const handleSideContainerAuditLogProps = (
		tabKey: any,
		formOption: any,
		activeRowKey: any
	) => {
		if (formOption === "template") {
			switch (tabKey) {
				case "phase":
					setAuditLogProps({
						identifierKeys: {},
						label: `Phase`,
						type: "phase_template",
						auditlogFilter: [
							{
								level: "phase_template",
								level_id: activeRowKey,
								event_type: { $in: ["add", "update"] },
								"ref_tags.well_programme_template_id": props?.otherData?._id,
							},
							{
								level: "task_template",
								event_type: { $in: ["add", "delete"] },
								"ref_tags.well_programme_template_id": props?.otherData?._id,
								"ref_tags.phase_template_id": activeRowKey,
							},
						],
					});
			}
		}
	};

	const setSideContainer = () => {
		if (sideContainerOpen) {
			return (
				<div className="side-container">
					<div className="generic-header" style={{ height: "50px" }}>
						{tabData?.configurationName ? (
							<>{tabData?.configurationName}</>
						) : tabKey == "project_tags" ||
						  tabKey == "well_purpose" ||
						  tabKey == "well_status" ? (
							<>
								{formOption.charAt(0).toUpperCase() +
									formOption.split("_").join(" ").slice(1)}{" "}
								{" configuration"}
							</>
						) : (
							<>
								{tabKey.charAt(0).toUpperCase() +
									tabKey.split("_").join(" ").slice(1)}{" "}
								{" configuration"}
							</>
						)}
					</div>
					<div
						// className="generic-content"
						style={{
							flex: "1",
							flexDirection: "column",
							padding: "16px",
							flexWrap: "nowrap",
							maxHeight: "100%",
							overflow: "auto",
							paddingLeft: "32px",
							position: "relative",
						}}
					>
						{
							<CustomForm
								setFormReset={setFormReset}
								handleFormSave={handleFormSave}
								setInitialForm={setInitialForm}
								tabKey={tabKey}
								formOption={formOption}
								formRef={formRef}
								dataOptions={extraData}
								filteredDataOptions={filteredExtraData}
								activeRowKey={activeRowKey}
								activeRecord={activeRecord}
								disabledKey={"disabled"}
								disabledTooltipMessage={disabledTooltipMessage}
							/>
						}
					</div>

					<div className="generic-footer" style={{ height: "50px" }}>
						{activeRowKey && (
							<>
								<Popconfirm
									placement="topLeft"
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											{getDeleteMessage(activeRowKey)}
										</div>
									}
									//description="Permanently delete this item? This action cannot be undone."
									onConfirm={handleFormDelete}
									overlayClassName="popconfirm-danger"
									onCancel={() => {
										// Handle cancel action if needed
									}}
									okText="Delete"
									okType="danger"
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									cancelText={<span>Cancel</span>}
									cancelButtonProps={{ ghost: true }}
								>
									<Button
										disabled={activeRecord?.disabled}
										className="ant-btn-secondary"
										style={
											isDeleteButtonVisible(tabKey)
												? { marginRight: "auto" }
												: { display: "none" }
										}
										danger
									>
										Delete
									</Button>
								</Popconfirm>
								{tabData.showSideContainerAuditLog === true && (
									<a
										style={{
											display: "flex",
											padding: "4px 10px",
											gap: "8px",
											cursor: "pointer",
										}}
										onClick={() => {
											setAuditLogModalOpen(true);
											handleSideContainerAuditLogProps(
												tabKey,
												formOption,
												activeRowKey
											);
										}}
									>
										{GetAntIcon("history")} Audit log
									</a>
								)}
							</>
						)}
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={handleFormCancel}
							okText="Continue editing"
							okType="default"
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>{fetchSaveMessage()}</>
								</div>
							}
							placement="topRight"
							open={modalVisible}
							cancelButtonProps={{ ghost: true }}
							cancelText="Cancel"
							onCancel={handleModalCancel}
							onConfirm={handleModalOk}
							okText="Proceed"
							okType="default"
						>
							<FormButtonSave
								activeKey={activeRowKey || activeRowID}
								form={formRef}
								setInitialForm={setInitialForm}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
								buttonType={props.buttonType || null}
								labelText={props.labelText || null}
								popconfirmTitle={popConfirmMessage}
							></FormButtonSave>
						</Popconfirm>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="crud-tab-table-modal-container">
			<div className="main-container">
				<div className="generic-header">
					{props.headerName || "Configurations"}
				</div>
				<div className="generic-content">
					<Tabs
						style={{ lineHeight: "1.55", height: "100%" }}
						tabPosition="left"
						onChange={handleTabChange}
						activeKey={tabKey}
						items={props.items}
					></Tabs>
					<div className="generic-table-container">
						<div className="generic-table-header">
							<span className="table-header-content">
								{props.items.map((item: any) => {
									if (item.key === tabKey) {
										return (
											item.directoryName || (
												<>
													{tabKey.charAt(0).toUpperCase() +
														tabKey.split("_").join(" ").slice(1)}
													{" directory"}
													{item.key === "tenant" && (
														<Popover content="Refresh tenant API links.">
															<Button
																onClick={() => {
																	refreshTenantRoutes()
																		.then(() => {
																			Emitter.emit("alert", {
																				type: "success",
																				description: "",
																				message:
																					"Successfully refreshed tenant API links.",
																				top: true,
																				closeable: false,
																				timeout: 3000,
																			});
																		})
																		.catch(() => {
																			refreshTenantRoutes().then(() => {
																				Emitter.emit("alert", {
																					type: "error",
																					description: "",
																					message:
																						"Failed to refreshed tenant API links.",
																					top: true,
																					closeable: false,
																					timeout: 3000,
																				});
																			});
																		});
																}}
																type="link"
																icon={GetAntIcon("reload")}
															></Button>
														</Popover>
													)}
												</>
											)
										);
									}
								})}
							</span>

							{/* Temporary use array to decide whether to include audit log or not */}
							{tabData?.tableAuditLog && (
								<a
									className="audit-log-button"
									onClick={() => {
										setAuditLogModalOpen(true);
										setAuditLogProps(tabData.tableAuditLog);
									}}
								>
									{GetAntIcon("history")} Audit log
								</a>
							)}

							{addButtonProp ? (
								<Dropdown
									disabled={!formReset || newForm}
									menu={{
										items: addButtonProp,
										onClick: (ev: any) => {
											resetForm();
											setFormOption(ev.key);
											setActiveRowKey(null);
											setActiveRowID(null);
											setActiveRecord(null);
											setNewForm(true);
											setSideContainerOpen(true);
											handleAddButton();
										},
									}}
									placement="bottomLeft"
								>
									<Button>+ Add</Button>
								</Dropdown>
							) : (
								<>
									{props.reorderModal && dataTable.length !== 0 && (
										<PhaseReorder
											data={dataTable}
											option={formOption}
											otherData={props?.otherData}
											user={props?.user}
										/>
									)}
									<Button
										onClick={() => {
											resetForm();
											setActiveRowKey(null);
											setActiveRowID(null);
											setActiveRecord(null);
											setSideContainerOpen(true);
											handleAddButton();
											setNewForm(true);
										}}
										style={
											isAddButtonVisible(tabKey) ? {} : { display: "none" }
										}
										disabled={!formReset || newForm}
									>
										+ Add
									</Button>
								</>
							)}
						</div>
						<div className="table-content" id="table-container">
							{ready && (
								<Table
									rowClassName={(record: any) => {
										let className = "";
										if (
											record?.uuid === activeRowKey ||
											record?.code === activeRowKey ||
											record?._id === activeRowKey ||
											(record.group_uuid === activeRowKey &&
												!record.uuid &&
												!record.code &&
												!record._id)
										) {
											className += "antd-table-row-active ";
										} else if (
											`${record.group_uuid}-${record.tag_uuid}` ===
												activeGroupKey ||
											`${record.group_uuid}-${record.type_uuid}` ===
												activeGroupKey
										) {
											className += "antd-table-row-active ";
										} else if (
											activeGroupKey?.includes(record.group_uuid) &&
											record.group_uuid != ""
										) {
											className += "antd-table-group-active ";
										}

										if (record.disabled) {
											className += "antd-table-row-disabled ";
										}
										return className;
									}}
									pagination={{
										defaultPageSize: 20,
										pageSizeOptions: [20, 50, 100],
										showSizeChanger: true,
										position: ["bottomCenter"],
									}}
									expandable={{
										expandedRowKeys: expandedRowKeys,
										onExpand: (expand: any, record: any) => {
											let keys = [...expandedRowKeys];
											if (expand) {
												keys.push(record.uuid);
											} else {
												let index = keys.findIndex(
													(item: any) => item == record.uuid
												);
												if (index != -1) {
													keys.splice(index, 1);
												}
											}
											setExpandRowKey(keys);
										},
									}}
									scroll={tableHeight && { y: tableHeight, x: 0 }}
									dataSource={filteredDataTable || dataTable}
									columns={filteredTableColumns || tableColumns}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			{setSideContainer()}
			{auditLogModalOpen && (
				<AuditLogModal
					{...auditLogProps}
					tenantKey={context?.currentTenantKey}
					open={auditLogModalOpen}
					handleClose={() => setAuditLogModalOpen(false)}
				/>
			)}
		</div>
	);
};

export default CRUDTabTableModal;
