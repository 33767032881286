import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Popover,
	Select,
	Space,
	Tabs,
	Tag,
	Divider,
} from "antd";
import Emitter from "../utils/emitter";
import { useForm, useWatch } from "antd/es/form/Form";
import "../assets/scss/projects.scss";
import FormButtonSave from "../components/FormButtonSave";
import FormButtonCancel from "../components/FormButtonCancel";
import { projectTest, projectType, regionType } from "../utils/test";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { countryCodes, countryList } from "../utils/countryCodes";
import { getProjectTagList } from "../services/api-server/project_tags";
import {
	addProjectItem,
	deleteProjectItem,
	getProject,
	getProjects,
	getProjectsCode,
	updateProjectItem,
	updateProjectItemAndCode,
} from "../services/api-server/projects";
import { getRolePermissionList } from "../services/api-server/roles_permissions";
import { getAllUsers } from "../services/api-server/user";
import { useLocation, useNavigate } from "react-router";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { GetAntIcon } from "../utils/ant_icons";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { getRegionList } from "../services/api-server/region";
import { getCompanyList } from "../services/api-server/company";
import LookupMissingTooltip from "../components/LookupMissingTooltip";
import ProjectConfiguration from "./projectTabs/ProjectConfiguration";
import { HomeContext } from "./Home";
import { getTenantSpecificUsers } from "../services/api-server/graphql";
import ProjectOutline from "./projectTabs/ProjectOutline";
import { getOperationTypeList } from "../services/api-server/operation_type";
import NoAccess from "./NoAccess";
// import ProjectDetails from "../projectTabs/ProjectDetails";

const { Option } = Select;

/*
	- currentProject is the project code of the current project
*/
function Projects(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [allData, setAllData] = useState<any>({});

	const [allProjects, setAllProjects] = useState<any>([]);
	const [currentProject, setCurrentProject] = useState<any>(null);
	const [currentProjectData, setCurrentProjectData] = useState<any>(null);
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [businessUnits, setBusinessUnits] = useState<any>(null);
	const [projectTags, setProjectTags] = useState<any>(null);
	const [operatingOffices, setOperatingOffices] = useState<any>(null);
	const [companies, setCompanies] = useState<any>(null);
	const [regions, setRegions] = useState<any>(null);
	const [countries, setCountries] = useState<any>(null);
	const [roles, setRoles] = useState<any>(null);
	const [users, setUsers] = useState<any>(null);
	const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

	const [initialForm, setInitialForm] = useState<any>(null);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<any>(false);

	const [history, setHistory] = useState<any>([]);
	const [sideContainerOpen, setSideContainerOpen] = useState<any>(false);
	// const [teamUpdated, setTeamUpdated] = useState<any>(false);

	const setTabPopOverContent = (type: any) => {
		if (currentProject == "Add new project") {
			return "Will be enabled after updating 'Project details'.";
		} else if (context?.currentProject?.project_type === "Consulting") {
			return "Not applicable to 'Consulting' project type.";
		} else if (
			type === "project-outline" &&
			!currentProjectData?.project_manager
		) {
			return "Will be enabled after updating 'Project team'.";
		} else {
			return "";
		}
	};

	const TabList: any = [
		{ label: "Project details", key: "project-details" },
		{
			label: (
				<Popover content={setTabPopOverContent("project-team")}>
					<span>Project team</span>
				</Popover>
			),
			key: "project-team",
			disabled:
				currentProject == "Add new project" ||
				context?.currentProject?.project_type === "Consulting"
					? true
					: false,
		},
		{
			label: (
				<Popover content={setTabPopOverContent("project-outline")}>
					<span>Project outline</span>
				</Popover>
			),
			key: "project-outline",
			disabled:
				currentProject == "Add new project" ||
				!currentProjectData?.project_manager ||
				context?.currentProject?.project_type === "Consulting"
					? true
					: false,
		},
	];
	const [tabKey, setTabKey] = useState<any>(() => {
		if (sessionStorage.getItem("main-tabkey")) {
			let sessionTabKey: any = sessionStorage.getItem("main-tabkey");
			let found = TabList.find((tab: any) => {
				return tab.key === sessionTabKey;
			});
			if (found) {
				return sessionTabKey;
			} else {
				sessionStorage.setItem("main-tabkey", TabList[0].key);
				return TabList[0].key;
			}
		} else {
			sessionStorage.setItem("main-tabkey", TabList[0].key);
			return TabList[0].key;
		}
	});
	const [isLoading, setIsLoding] = useState<any>(true);

	// Load all the data needed for the form
	useEffect(() => {
		if ((context.currentTenantKey, context.allData)) {
			Emitter.emit("loading", true);
			let allData: any = {};
			Promise.all([
				getBusinessUnitList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						setBusinessUnits(data);
						allData.business_units = data;
					}
				}),

				getProjectTagList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.color = item.color;
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						allData.project_tags = data;
						setProjectTags(data);
					}
				}),

				getOperatingOfficeList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = `${item.name}, ${item.country}`;
							return item;
						});
						data = sortData(data);
						allData.operating_offices = data;
						setOperatingOffices(data);
					}
				}),

				getOperationTypeList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						allData.operation_types = data;
					}
				}),

				getProjects(
					context.currentTenantKey,
					context.currentTenantData.company_list
				).then((_data: any) => {
					if (_data) {
						let data = _data
							.filter((item: any) => !item.is_removed)
							.map((item: any) => {
								item.value = item.project_code;
								item.label = item.project_name;
								return item;
							});
						data = sortData(data);
						allData.projects = data;
						setAllProjects(data);
					}
				}),

				getRegionList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						allData.regions = data;
						setRegions(data);
					}
				}),

				// getCompanyList(context?.currentTenantData?.company_list).then(
				getCompanyList([], true).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						allData.companies = data;
						setCompanies(data);
					}
				}),

				getRolePermissionList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.key = item.uuid;
							item.value = item.name;
							item.label = item.name;
							return item;
						});
						data = sortData(data);
						allData.roles = data;
						setRoles(data);
					}
				}),

				getTenantSpecificUsers(context?.currentTenantData?.uuid).then(
					(tenantUsers: any) => {
						if (tenantUsers) {
							let data = tenantUsers.map((item: any) => {
								// console.log(item);
								item.key = item.id;
								item.value = item.mail;
								item.label = item.mail;
								return item;
							});
							data = sortData(data);
							allData.users = data;

							setUsers(data);
						}
						if (context?.currentTenantData?.project_managers) {
							let projectManagerList =
								context?.currentTenantData?.project_managers;
							let data = projectManagerList.map((item: any) => {
								let obj: any = {};
								obj.key = item;
								obj.value = item;
								obj.label = item;
								return obj;
							});
							data = sortData(data);
							allData.project_managers = data;
						}
					}
				),
			])
				.then((data: any) => {
					setAllData(allData);
				})
				.finally(() => {
					if (location.state) {
						let foundProject = allData?.projects?.find(
							(project: any) =>
								project?.project_code === location.state.project_code
						);

						if (foundProject) {
							resetForm(foundProject, allData);
							setCurrentProject(foundProject.project_code);
							setCurrentProjectData(foundProject);
							context.setCurrentProject(foundProject);
						} else {
							setCurrentProject(null);
							setCurrentProjectData(null);
						}
					} else {
						if (
							location.pathname.split("/")[2] === "add-new-project" &&
							location.pathname.split("/").length == 3
						) {
							setCurrentProject("Add new project");
							setCurrentProjectData(null);
							resetForm(null, allData);
						} else {
							let foundProject = allData?.projects?.find(
								(project: any) =>
									project?.project_code === location.pathname.split("/")[2]
							);

							if (foundProject) {
								resetForm(foundProject, allData);
								setCurrentProject(foundProject.project_code);
								setCurrentProjectData(null);
								context.setCurrentProject(foundProject);
							} else {
								setCurrentProject(null);
								setCurrentProjectData(null);
							}
						}
					}
					// else if (
					// 	localStorage.getItem("currentProject") != null &&
					// 	localStorage.getItem("currentProject") != "Add new project"
					// ) {
					// 	handleProjectChange(
					// 		localStorage.getItem("currentProject"),
					// 		allData
					// 	);
					// } else {
					// 	Emitter.emit("projectName", "Add new project");
					// 	setCurrentProject("Add new project");
					// }
					setIsLoding(false);
					Emitter.emit("loading", false);
				});
		}
	}, [context.currentTenantKey, context.allData, location.pathname]);

	// Set the tab to 'project-details' when the currentProject is "Add new project"
	useEffect(() => {
		if (currentProject) {
			// Initialize the currentProject in local storage
			localStorage.setItem("currentProject", currentProject);
			if (currentProject === "Add new project") {
				setTabKey("project-details");
			} else {
			}
		}
	}, [currentProject]);

	// check lookup values for?
	const checkLookup = (key: any, value: any, data: any) => {
		switch (key) {
			case "business_unit":
				return (
					data["business_units"]?.find((element: any) => {
						return element.uuid == value;
					})?.uuid || null
				);
			case "client":
				return (
					data["companies"]?.find((element: any) => {
						return element.uuid == value;
					})?.uuid || null
				);
			case "region":
				return (
					data["regions"]?.find((element: any) => {
						return element.uuid == value;
					})?.uuid || null
				);
			case "country":
				return (
					countryList?.find((element: any) => {
						return element["alpha-3"] == value;
					})["alpha-3"] || null
				);
			case "operating_office":
				return (
					data["operating_offices"]?.find((element: any) => {
						return element.uuid == value;
					})?.uuid || null
				);
			case "project_tags":
				return value?.map((tag: any) => {
					return (
						data["project_tags"]?.find((element: any) => {
							return element.uuid == tag;
						})?.uuid || "Lookup missing"
					);
				});

			default:
				return value;
		}
	};

	// reset form
	const resetForm = (values: any = null, data: any = null) => {
		let filteredCountries: any = [];
		if (formRef) {
			setFormReset(true);
			if (values) {
				Object.keys(values).forEach((key: any) => {
					values[key] = checkLookup(key, values[key], data || allData);
					// console.log(key, values[key]);
				});
			}
			formRef.resetFields();

			formRef.setFieldsValue(values);
			if (formRef.getFieldValue("region") || values?.region) {
				let regionList = data?.regions || regions;
				let indexArray = regionList?.find(
					(object: any) => object.uuid === formRef.getFieldValue("region")
				);
				if (indexArray) {
					let filteredData: any = [];
					filteredData = indexArray?.countries
						?.map((object: any) => {
							let countries = countryList?.find(
								(element: any) => element["alpha-3"] == object
							);
							if (countries)
								return {
									label: countries.name,
									value: countries["alpha-3"],
								};
						})
						.filter((item: any) => item);

					filteredCountries = filteredData.length > 0 ? filteredData : null;

					setCountries(filteredCountries);
				} else {
					setCountries(filteredCountries);
				}
			} else {
				setCountries(null);
			}
		}
	};

	// Function to sort array of objects alphabetically based on property's name
	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	// Check if lookup value exist in the dropdown options
	const renderLookupMissing = (key: any) => {
		let value = formRef.getFieldValue(key);
		console.log(formRef);
		console.log(value);
		if (value === null && value != "") {
			return (
				<LookupMissingTooltip
					userRole={props.params.userRole}
					content={
						<div
							style={{
								position: "absolute",
								padding: "0 11px",
								color: "#FAAD14",

								top: "5px",
								display: "flex",
								gap: "8px",
							}}
						>
							<WarningOutlined />
							Lookup missing
						</div>
					}
				/>
			);
		}
	};

	// Handle when project has change / adding a new project
	const handleProjectChange = async (
		key: any = null,
		data: any = null,
		manual: boolean = false
	) => {
		try {
			let projects = data?.projects || allProjects;
			await context.handlePageChange();
			// Emitter.emit("loading", true);
			setCurrentProject(key);

			//Always set tabKey to project-details when project has changed
			if (manual) {
				setTabKey("project-details");
				sessionStorage.removeItem("tabKey");
			}

			if (key == "Add new project" || key == null) {
				Emitter.emit("projectName", "Add new project");
				setTabKey("project-details");
				sessionStorage.removeItem("tabKey");
				resetForm(null);
			} else {
				sessionStorage.removeItem("tabKey");

				const project = projects.find((project: any) => {
					// Emit the project name to set the breadcrumb
					Emitter.emit("projectName", project.project_name);
					return project.project_code == key;
				});
				resetForm(project, data);
				setCurrentProjectData(project);
				navigate(`/projects/${key}/project-settings`, {
					state: project,
				});
			}
			// Emitter.emit("loading", false);
		} catch {}
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			setFormReset(true);
			sessionStorage.setItem("main-tabkey", key);
			const project = allProjects.find((project: any) => {
				return project.project_code == currentProject;
			});
			resetForm(project, null);
			setSideContainerOpen(false);
		} catch {}
	};

	const checkProjectManager = (email: any) => {
		if (
			context.currentTenantData?.project_managers?.some(
				(mail: any) => mail.toLowerCase() === email.toLowerCase()
			)
		) {
			return email;
		} else {
			return null;
		}
	};

	// Handle antd Select value change
	const handleSelectChange = (key: any, value: any) => {
		setHistory([...history, { key: key, value: value }]);
		formRef.setFieldValue(key, value);
	};

	// Handle form save
	const handleFormSave = (formValues: any | null = null, type: any = null) => {
		let values = formValues || formRef.getFieldsValue();

		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		Emitter.emit("loading", true);
		function getRandomNumberInRangeWithDecimals(
			min: any,
			max: any,
			decimalPlaces: any
		) {
			const range = max - min;
			const randomValue = Math.random() * range + min;
			const factor = 10 ** decimalPlaces;
			return Math.round(randomValue * factor) / factor;
		}
		const BU = businessUnits.find(
			(element: any) => element.uuid == values.business_unit
		);
		const foundClient = companies.find(
			(element: any) => element.uuid == values.client
		);
		const foundCountry = countryList.find(
			(element: any) => element["alpha-3"] == values.country
		);
		if (foundCountry) {
			values.latlong = [foundCountry.latitude, foundCountry.longitude];
		} else {
			values.latlong = [0, 0];
		}
		const year = new Date()
			.getFullYear()
			.toString()
			.substring(2, 4)
			.toUpperCase();
		const client = foundClient.name
			?.replace(/\s/g, "")
			.substring(0, 4)
			.toUpperCase();
		values.is_removed = false;
		values.status = "New";

		if (currentProject != "Add new project" && currentProject) {
			values.modified_on = Date.now();
			if (type == "code") {
				values.previous_project_code = values.project_code;
				values.project_code = `${BU.id}${year}${client}`;
				getProjectsCode(context.currentTenantKey, values.project_code)
					.then((data: any) => {
						if (data) {
							const latest_code = data
								.filter((element: any) => {
									let str = element.project_code.slice(0, -3);
									return str === values.project_code;
								})
								.map((element: any) => element.project_code)
								.sort((a: any, b: any) => a.localeCompare(b))
								.pop();

							const code = latest_code.slice(-3);
							const inputString = code;
							const intValue = parseInt(inputString, 10); // Parse the string as an integer
							const incrementedValue = intValue + 1; // Increment the integer by 1
							const formattedString = String(incrementedValue).padStart(3, "0"); // Format as a string with leading zeros
							values.project_code += formattedString;
						} else {
							values.project_code += "001";
						}
						updateProjectItemAndCode(
							context.currentTenantKey,
							currentProject,
							values,
							initialForm,
							props?.params?.user,
							[
								"project_name",
								"project_type",
								"business_unit",
								"client",
								"region",
								"country",
								"project_tags",
								"operating_office",
							]
						)
							.then((updatedData: any) => {
								Emitter.emit("alert", {
									type: "success",
									message: "Project has been successfully updated.",
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
								context.setCurrentProject(updatedData);
								setCurrentProjectData(updatedData);
								// resetForm(updatedData);
								// Emitter.emit("projectName", updatedData.project_name);

								navigate(`/projects/${values.project_code}/project-settings`, {
									state: { ...updatedData },
								});
								// console.log(data);
								getProjects(
									context.currentTenantKey,
									context?.currentTenantData?.company_list
								)
									.then((_data: any) => {
										// console.log(_data);
										if (_data) {
											let data = _data
												.filter((item: any) => !item.is_removed)
												.map((item: any) => {
													if (!item.is_removed) {
														item.value = item.project_code;
														item.label = item.project_name;
														return item;
													}
												});
											context.allData.projects = data;
										}
									})
									.catch((error: any) => {
										// console.log(error);
									});
							})
							.catch((error: any) => {
								if (error.code === 11000) {
									Emitter.emit("alert", {
										type: "error",
										message: `This project already exists, please ensure the project ${
											Object.keys(error.keyPattern)[0]
										} is unique. Changes are not saved.`,
										description: "",
										top: true,
										closeable: false,
										timeout: 4000,
									});
								} else {
									Emitter.emit("alert", {
										type: "error",
										message: "Failed to update project. Please try again",
										description: "",
										top: true,
										closeable: false,
										timeout: 3000,
									});
								}
								resetForm();
							})
							.finally(() => {
								Emitter.emit("loading", false);
							});
					})
					.catch((error: any) => {
						// console.log(error);
					});
			} else if (type == "New") {
				values.project_code = `${BU.id}${year}${client}`;
				values.created_on = Date.now();
				values.modified_on = Date.now();
				values.project_manager = checkProjectManager(
					props?.params?.user?.email
				);
				getProjectsCode(context.currentTenantKey, values.project_code)
					.then((data: any) => {
						if (data) {
							const latest_code = data
								.filter((element: any) => {
									let str = element.project_code.slice(0, -3);
									return str === values.project_code;
								})
								.map((element: any) => element.project_code)
								.sort((a: any, b: any) => a.localeCompare(b))
								.pop();

							const code = latest_code.slice(-3);
							const inputString = code;
							const intValue = parseInt(inputString, 10); // Parse the string as an integer
							const incrementedValue = intValue + 1; // Increment the integer by 1
							const formattedString = String(incrementedValue).padStart(3, "0"); // Format as a string with leading zeros
							values.project_code += formattedString;
						} else {
							values.project_code += "001";
						}
						addProjectItem(
							context.currentTenantKey,
							values,
							props?.params?.user
						)
							.then((data: any) => {
								Emitter.emit("alert", {
									type: "success",
									message: "New project has been successfully added.",
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
								resetForm(data);
								if (context.allData?.projects?.length > 0)
									context.allData?.projects?.push(data);
								else {
									context.allData.projects = [data];
								}
								navigate(`/projects/${values.project_code}/project-settings`, {
									state: { ...data },
								});
								context.setCurrentProject(data);
								setCurrentProjectData(data);
								if (values.project_type != "Consulting") {
									setTabKey("project-team");
								}

								// getProjects(
								// 	context.currentTenantKey,
								// 	context?.currentTenantData?.company_list
								// ).then((_data: any) => {
								// 	if (_data) {
								// 		let data = _data
								// 			.filter((item: any) => !item.is_removed)
								// 			.map((item: any) => {
								// 				if (!item.is_removed) {
								// 					item.value = item.project_code;
								// 					item.label = item.project_name;
								// 					return item;
								// 				}
								// 			});

								// 		setAllProjects(data);
								// 		setAllData({
								// 			...allData,
								// 			projects: data,
								// 		});
								// 		setCurrentProject(values.project_code);
								// 	}
								// });
							})
							.catch((error: any) => {
								if (error.code === 11000) {
									Emitter.emit("alert", {
										type: "error",
										message: `This project already exists, please ensure the project ${
											Object.keys(error.keyPattern)[0]
										} is unique. Changes are not saved.`,
										description: "",
										top: true,
										closeable: false,
										timeout: 4000,
									});
								} else {
									Emitter.emit("alert", {
										type: "error",
										message: "Failed to add new project. Please try again.",
										description: "",
										top: true,
										closeable: false,
										timeout: 3000,
									});
								}
							});
					})
					.catch((error: any) => {
						// console.log(error);
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			} else {
				updateProjectItem(
					context.currentTenantKey,
					currentProject,
					values,
					initialForm,
					props.params?.user,
					[
						"project_name",
						"project_type",
						"business_unit",
						"client",
						"region",
						"country",
						"project_tags",
						"operating_office",
					]
				)
					.then((updatedData: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: "Project has been successfully updated.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm(updatedData);
						context.setCurrentProject(updatedData);
						setCurrentProjectData(updatedData);
						getProjects(
							context.currentTenantKey,
							context?.currentTenantData?.company_list
						)
							.then((_data: any) => {
								// console.log(_data);
								if (_data) {
									let data = _data
										.filter((item: any) => !item.is_removed)
										.map((item: any) => {
											if (!item.is_removed) {
												item.value = item.project_code;
												item.label = item.project_name;
												return item;
											}
										});
									// console.log(data);
									setAllProjects(data);
									setAllData({ ...allData, projects: data });
									context.allData.projects = data;
								}
							})
							.catch((error: any) => {
								// console.log(error);
							});
					})
					.catch((error: any) => {
						Emitter.emit("alert", {
							type: "error",
							message: "Failed to update project. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						resetForm();
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			}
		} else {
			values.project_code = `${BU.id}${year}${client}`;
			values.created_on = Date.now();
			values.modified_on = Date.now();
			values.project_manager = checkProjectManager(props?.params?.user?.email);
			getProjectsCode(context.currentTenantKey, values.project_code)
				.then((data: any) => {
					// console.log(data);
					if (data) {
						const latest_code = data
							.filter((element: any) => {
								let str = element.project_code.slice(0, -3);
								return str === values.project_code;
							})
							.map((element: any) => element.project_code)
							.sort((a: any, b: any) => a.localeCompare(b))
							.pop();

						const code = latest_code.slice(-3);
						const inputString = code;
						const intValue = parseInt(inputString, 10); // Parse the string as an integer
						const incrementedValue = intValue + 1; // Increment the integer by 1
						const formattedString = String(incrementedValue).padStart(3, "0"); // Format as a string with leading zeros
						values.project_code += formattedString;
					} else {
						values.project_code += "001";
					}
					addProjectItem(context.currentTenantKey, values, props?.params?.user)
						.then((data: any) => {
							Emitter.emit("alert", {
								type: "success",
								message: "New project has been successfully added.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
							resetForm(data);
							if (context.allData?.projects?.length > 0)
								context.allData?.projects?.push(data);
							else {
								context.allData.projects = [data];
							}
							navigate(`/projects/${values.project_code}/project-settings`, {
								state: { ...data },
							});
							context.setCurrentProject(data);
							setCurrentProjectData(data);
							if (values.project_type != "Consulting") {
								setTabKey("project-team");
							}
							// getProjects(
							// 	context.currentTenantKey,
							// 	context.currentTenantData.company_list
							// ).then((_data: any) => {
							// 	if (_data) {
							// 		let data = _data
							// 			.filter((item: any) => !item.is_removed)
							// 			.map((item: any) => {
							// 				if (!item.is_removed) {
							// 					item.value = item.project_code;
							// 					item.label = item.project_name;
							// 					return item;
							// 				}
							// 			});
							// 		setAllProjects(data);
							// 		setAllData({ ...allData, projects: data });
							// 		setCurrentProject(values.project_code);
							// 		Emitter.emit("projectName", values.project_name);
							// 	}
							// });
						})
						.catch((error: any) => {
							if (error.code === 11000) {
								Emitter.emit("alert", {
									type: "error",
									message: `This project already exists, please ensure the project ${
										Object.keys(error.keyPattern)[0]
									} is unique. Changes are not saved.`,
									description: "",
									top: true,
									closeable: false,
									timeout: 4000,
								});
							} else {
								Emitter.emit("alert", {
									type: "error",
									message: "Failed to add new project. Please try again.",
									description: "",
									top: true,
									closeable: false,
									timeout: 3000,
								});
							}
						});
				})
				.catch((error: any) => {
					// console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	};

	// handle project configurations save (anything that is not project details)
	const handleProjectConfigSave = () => {
		getProjects(
			context.currentTenantKey,
			context.currentTenantData.company_list
		).then((_data: any) => {
			if (_data) {
				let data = _data
					.filter((item: any) => !item.is_removed)
					.map((item: any) => {
						if (!item.is_removed) {
							item.value = item.project_code;
							item.label = item.project_name;
							return item;
						}
					});

				let updatedProject: any = _data.find(
					(project: any) => project.project_code === currentProject
				);
				// setTeamUpdated(true);
				setAllProjects(data);
				setAllData({ ...allData, projects: data });
				setCurrentProjectData(updatedProject);
			}
		});
	};

	// Handle form delete
	const handleFormDelete = (project_code: any) => {
		Emitter.emit("loading", true);
		context?.handleFormUpdating(false);
		context.setCurrentProject(null);
		setCurrentProjectData(null);
		Emitter.emit("projectName", "Add new project");
		const values = initialForm;
		values.project_code = project_code;
		values.is_removed = true;
		updateProjectItem(
			context.currentTenantKey,
			project_code,
			values,
			initialForm,
			props.params?.user,
			[
				"project_name",
				"project_type",
				"business_unit",
				"client",
				"region",
				"country",
				"project_tags",
				"operating_office",
			]
		)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: "Project has been successfully deleted.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});

				navigate("/projects/add-new-project");
				resetForm();
			})
			.catch(() => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to delete project. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
				navigate("/projects", { replace: true });
			});
	};

	// Handle form Cancel
	const handleFormCancel = () => {
		context?.handleFormUpdating(false);
		if (currentProject != "Add new project") {
			const project = allProjects.find((project: any) => {
				return project.project_code == currentProject;
			});
			resetForm(project);
		} else {
			resetForm();
		}
	};

	const checkCode = () => {
		if (
			(initialForm?.business_unit !== formRef.getFieldsValue().business_unit ||
				initialForm?.client !== formRef.getFieldsValue().client) &&
			initialForm?.client &&
			initialForm?.business_unit
		) {
			return true;
		} else {
			return false;
		}
	};

	const tabContent = () => {
		switch (tabKey) {
			case "project-details":
				// return (
				// 	<ProjectDetails
				// 		handleProjectConfigSave={handleProjectConfigSave}
				// 		currentProject={currentProject}
				// 		resetForm={resetForm}
				// 		data={allData}
				// 	/>
				// );
				return (
					<div className="project-details-tab-content">
						<div className="main-container">
							<div className="generic-header">Settings</div>
							<div className="generic-content">
								<div className="project-settings-form">
									{currentProject !== "Add new project" && currentProject && (
										<span className="project-id"># {currentProject}</span>
									)}
									<Form
										layout={"vertical"}
										form={formRef}
										className="project-form"
										requiredMark="optional"
										style={{
											gap: "20px",
											display: "grid",
										}}
										onChange={(event: any) => {
											setFormReset(false);
										}}
										onSelect={() => {
											setFormReset(false);
										}}
									>
										<Form.Item
											label="Project title"
											name="project_name"
											style={{ gridArea: "a" }}
											rules={[{ required: true, message: "" }]}
										>
											<Input
												autoComplete="off"
												count={{
													show: true,
													max: 50,
												}}
												onChange={(event: any) => {
													const value = event.target.value
														.replace(/^ /, "")
														.replace(/\s+/g, " ")
														.slice(0, 50);
													// .replace(/[^a-zA-Z0-9 ]/g, "");
													formRef.setFieldsValue({
														project_name: value,
													});
												}}
											></Input>
										</Form.Item>
										<Form.Item
											label="Project type"
											name="project_type"
											tooltip={{
												title:
													"Select the best category that aligns with the goals and characteristics of your project.",
												icon: GetAntIcon("question3"),
												placement: "right",
												overlayStyle: { maxWidth: "400px" },
											}}
											style={{ gridArea: "c" }}
											rules={[{ required: true, message: "" }]}
										>
											<Select
												disabled={!(currentProject === "Add new project")}
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={sortData(projectType)}
												onChange={(value: any) => {
													handleSelectChange("project_type", value);
												}}
											></Select>
											{renderLookupMissing("project_type")}
										</Form.Item>

										<Form.Item
											label="Strategic business unit"
											name="business_unit"
											style={{ gridArea: "d" }}
											rules={[{ required: true, message: "" }]}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={businessUnits}
												onChange={(value: any) => {
													handleSelectChange("business_unit", value);
												}}
											></Select>
											{renderLookupMissing("business_unit")}
										</Form.Item>
										<Form.Item
											label="Client"
											name="client"
											style={{ gridArea: "e" }}
											rules={[{ required: true, message: "" }]}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={companies}
												onChange={(value: any) => {
													handleSelectChange("client", value);
												}}
											></Select>
											{renderLookupMissing("client")}
										</Form.Item>
										<Form.Item
											label="Region"
											name="region"
											style={{ gridArea: "f" }}
											rules={[{ required: true, message: "" }]}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												options={regions}
												showSearch
												onChange={(value: any) => {
													handleSelectChange("region", value);
													formRef.setFieldValue("country", undefined);
													let indexArray = regions.find(
														(object: any) => object.uuid === value
													);
													if (indexArray) {
														let filteredData: any = [];
														filteredData = indexArray?.countries
															?.map((object: any) => {
																let countries = countryList.find(
																	(element: any) => element["alpha-3"] == object
																);
																if (countries)
																	return {
																		label: countries.name,
																		value: countries["alpha-3"],
																	};
															})
															.filter((item: any) => item);

														setCountries(filteredData);
													}
												}}
											></Select>
											{renderLookupMissing("region")}
										</Form.Item>
										<Form.Item
											label="Country"
											name="country"
											style={{ gridArea: "g" }}
											rules={[{ required: true, message: "" }]}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={countries}
												onChange={(value: any) => {
													handleSelectChange("country", value);
												}}
											></Select>
											{renderLookupMissing("country")}
										</Form.Item>
										<Form.Item
											label="Operating office"
											name="operating_office"
											style={{ gridArea: "j" }}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={operatingOffices}
												onChange={(value: any) => {
													handleSelectChange("operating_office", value);
												}}
											></Select>
											{renderLookupMissing("operating_office")}
										</Form.Item>
										<Form.Item
											label="Project tags"
											name="project_tags"
											style={{ gridArea: "k" }}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentNode}
												showSearch
												options={projectTags}
												mode="multiple"
												tagRender={(props: any) => {
													let tag = projectTags?.find((element: any) => {
														return props.label == element.label;
													});
													if (tag) {
														return (
															<Tag
																style={{
																	whiteSpace: "normal",
																	wordWrap: "break-word",
																}}
																color={tag.color}
															>
																{tag.label}
															</Tag>
														);
													}
													return <></>;
												}}
											></Select>
										</Form.Item>
									</Form>
								</div>
							</div>
							<div className="generic-footer">
								{currentProject && currentProject != "Add new project" && (
									<Popconfirm
										overlayInnerStyle={{
											maxWidth: "300px",
											padding: "16px",
										}}
										placement="topLeft"
										okText="Delete"
										okType="danger"
										title=""
										icon={
											<ExclamationCircleOutlined style={{ color: "grey" }} />
										}
										cancelText={"Cancel"}
										cancelButtonProps={{ ghost: true }}
										overlayClassName="popconfirm-danger"
										description="Permanently delete this project? This action cannot be undone."
										onOpenChange={(open: any) => {
											if (!open) {
												setEmptyModalOpen(false);
											}
										}}
										onConfirm={() => {
											setEmptyModalOpen(false);
											handleFormDelete(currentProject);
										}}
										onCancel={() => {
											setEmptyModalOpen(false);
										}}
									>
										<Button
											className="ant-btn-secondary"
											style={{ marginRight: "auto" }}
											danger
										>
											Delete
										</Button>
									</Popconfirm>
								)}
								<FormButtonCancel
									form={formRef}
									handleFormCancel={handleFormCancel}
									formReset={formReset}
								></FormButtonCancel>
								<Popover
									open={popOverVisibility}
									onOpenChange={(boolean: any) => {
										if (checkCode()) {
											setPopOverVisibility(boolean);
										} else {
											setPopOverVisibility(false);
										}
									}}
									overlayStyle={{
										maxWidth: "420px",
										// background: "rgba(57,57,57,1)",
										borderRadius: "8px",
										// padding: "16px",
									}}
									style={{ background: "transparent" }}
									trigger={"click"}
									content={
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												gap: "8px",
											}}
										>
											<Button
												className="ant-button-default"
												style={{
													marginRight: "auto",
													border: "1px solid rgba(255, 255, 255, 0.1)",
												}}
												ghost
												onClick={() => {
													setPopOverVisibility(false);
													handleFormSave(null, "New");
												}}
											>
												Create new
											</Button>
											<Button
												className="ant-button-default"
												style={{
													border: "1px solid rgba(255, 255, 255, 0.1)",
												}}
												ghost
												onClick={() => {
													setPopOverVisibility(false);
												}}
											>
												Cancel
											</Button>
											<Button
												className="ant-button-default"
												onClick={() => {
													setPopOverVisibility(false);
													handleFormSave(null, "code");
												}}
											>
												Proceed
											</Button>
										</div>
									}
									title={
										<Space>
											<div style={{ color: "grey" }}>
												{GetAntIcon("exclamation")}
											</div>
											<span style={{ fontWeight: "400" }}>
												Changing the business unit or client info affects the
												project code. Do you wish to proceed?
											</span>
										</Space>
									}
								>
									<div>
										<FormButtonSave
											form={formRef}
											setInitialForm={setInitialForm}
											handleFormSave={handleFormSave}
											formReset={formReset}
										></FormButtonSave>
									</div>
								</Popover>
							</div>
						</div>
					</div>

					// <ProjectDetails currentProject={currentProject} data={allData} />
				);
			case "project-team":
				return (
					<ProjectConfiguration
						handleProjectConfigSave={handleProjectConfigSave}
						currentProject={currentProject}
						user={props.params?.user}
						// resetForm={resetForm}
						// updateTeam={() => {
						// 	setTeamUpdated(true);
						// }}
						data={allData}
					/>
				);
			case "project-outline":
				return (
					<ProjectOutline
						handleProjectConfigSave={handleProjectConfigSave}
						currentProject={currentProject}
						resetForm={resetForm}
						data={allData}
						user={props.params?.user}
						handleSideContainerOpen={(condition: any) => {
							setSideContainerOpen(condition);
						}}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			{currentProject && !isLoading && (
				<div className="generic-container">
					<Space direction="vertical">
						<Select
							getPopupContainer={(trigger: any) => trigger.parentNode}
							className="title-selector"
							style={{
								fontSize: "20px",
								fontWeight: "600",
								color: "rgba(255, 255, 255, 0.85)",
								width: "auto",
							}}
							options={allProjects}
							value={currentProject}
							open={dropdownOpen}
							onClick={() => {
								setDropdownOpen(!dropdownOpen);
							}}
							onChange={(key: any) => {
								handleProjectChange(key, null, true);
								setDropdownOpen(false);
							}}
							onBlur={(event: any) => {
								event.preventDefault();
								// console.log(event.relatedTarget);
								setDropdownOpen(false);
							}}
							dropdownStyle={{ minWidth: "100%", width: "auto" }}
							dropdownRender={(menu) => <>{menu}</>}
						></Select>

						<Tabs
							activeKey={tabKey}
							onChange={handleTabChange}
							items={TabList}
						></Tabs>
					</Space>
					<div className="project-settings-tab-content">{tabContent()}</div>

					{/* <div className="side-profile-container" style={{ flex: "1" }}></div> */}

					<Modal
						className="empty-modal"
						open={emptyModalOpen}
						closable={false}
						maskClosable={true}
						onCancel={() => {
							setEmptyModalOpen(false);
						}}
						zIndex={1050}
						footer={<></>}
						style={{ background: "transparent" }}
					>
						<></>
					</Modal>
				</div>
			)}
			{!currentProject && !isLoading && (
				<>
					<NoAccess text={"This project does not exist."} />
				</>
			)}
		</>
	);
}

Projects.propTypes = {
	params: PropTypes.any,
};

export default Projects;
