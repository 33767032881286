/**
 * default exports for other modules to use
 */

import Emitter from "./emitter";

export const url =
	window.location.hostname === "localhost"
		? `http://localhost:${process.env.REACT_APP_PORT_SERVER}/api`
		: `${window.location.origin}/api`;
export const superAdminRole: any = process.env.REACT_APP_SUPER_ADMIN_TAG;
export let accessToken: any = localStorage.getItem(`accessToken`);
export const idToken: any = localStorage.getItem(`idToken`);
export let pbiToken: any = localStorage.getItem(`pbiToken`);
export let AuthHeader: any = {
	headers: {
		Authorization: accessToken,
	},
	withCredentials: true,
};

// console.log(window.location);
Emitter.on("refreshed", () => {
	accessToken = localStorage.getItem(`accessToken`);
	pbiToken = localStorage.getItem(`pbiToken`);
	AuthHeader = {
		headers: {
			Authorization: accessToken,
		},
		withCredentials: true,
	};
});
